.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100vw;
  max-height: 100vh;
}

.responsive-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 80vh;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .responsive-image {
      width: 100vw;
      height: 100vh;
      max-width: none;
      max-height: none;
  }
  .overlay-icon {
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 768px) {
  .container {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    /* padding: 1rem; */
    box-sizing: border-box;
  }
  .overlay-icon {
      width: 60px;
      height: 60px;
  }
}

@media (min-width: 1024px) {
  .overlay-icon {
      width: 70px;
      height: 70px;
  }
}
